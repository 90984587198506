import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const UnterstützemPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ort: file(relativePath: { eq: "wahl2020/kandidatenreihe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid mobile-header-bg'
        style={{ backgroundPosition: 'top center' }}
        fluid={data.ort.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Unterstützen
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          Politische Arbeit braucht Unterstützung. Unterstützung vor Ort durch
          Mitglieder, aber auch finanziell.
        </p>
        <p>
          Sie können unsere politische Arbeit mit einer Spende unterstützen.
        </p>

        <p>Spendenkonto:</p>
        <p className='text-center'>
          CSU Neutraubling
          <br />
          IBAN: DE10 7505 0000 0030 9038 27
          <br />
          BIC: BYLADEM1RGB
          <br />
          Sparkasse Regensburg
        </p>

        <p>
          Bitte beachten:
          <br />
          Wir bitten Sie, bei Ihrer Überweisung im Verwendungszweck unbedingt
          Ihre vollständige Adresse anzugeben. Auf diese Weise können wir Ihre
          Spende korrekt erfassen und Ihnen eine entsprechende Spendenquittung
          zusenden. Bei weiteren Fragen sprechen Sie uns gerne an!
        </p>

        <p>
          Ihre Spende wird vom Gesetzgeber als Beitrag zur Stärkung des
          demokratischen Gemeinwesens steuerlich begünstigt. Konkret reduziert
          sich Ihre Einkommenssteuer um die Hälfte des gespendeten Betrages –
          bei Spenden bis zu einer Höhe von 1.650 Euro bei Einzelveranlagung
          oder 3.300 Euro bei Zusammenveranlagung. So bekommen Sie bei einer
          500-Euro-Spende die Hälfte, also 250 Euro, rückerstattet. Spenden, die
          über die genannten Grenzen hinausgehen, können als Sonderausgaben
          steuerlich geltend gemacht werden. Sofern Ihre Spende 10.000 Euro oder
          mehr beträgt, muss sie nach Parteiengesetz unter Angabe des
          Spendernamens veröffentlicht werden.
        </p>
      </div>
    </Layout>
  )
}

export default UnterstützemPage
